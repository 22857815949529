<template>
    <div class="custom-slider" style="margin-top: 20px">
        <input
                ref="regionalForecastSlider"
                type="range"
                :min="minValue"
                :max="maxValue"
                :step="stepValue"
                v-model="selectedValue"
                @input="onSliderInput"
        />
        <b-button class="btn-sm play-button" @click="togglePlayStop" variant="primary">
            {{ playing ? 'Stop' : 'Přehrát' }}
        </b-button>
    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import debounce from 'lodash/debounce';
import {mapActions, mapGetters} from "vuex";
import {BButton} from "bootstrap-vue";

export default {
    name: "RegionalForecastSlider",
    props: {
        initialValue: {
            default: 1,
        },
    },
    data() {
        return {
            minValue: 1,
            maxValue: 1,
            stepValue: 1,
            selectedValue: this.initialValue,
            playing: false,
        };
    },
    mounted() {
        // console.log("SLIDER MOUNTED")
        if (this.getRegionalForecastTimes) {
            this.maxValue = this.getRegionalForecastTimes.length;
        }
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        getRegionalForecastTimes: function (newValue) {
            // console.log("Inside watch for getRegionalForecastTimes ...")
            if (this.getRegionalForecastTimes) {
                this.maxValue = this.getRegionalForecastTimes.length;
            }
        },
        isLoading: function () {
            // console.log("Inside watch for isLoading ...")
            this.resetPlay(); // forecast reload
        },
        selectedValue:function (newValue) {
            // console.log("Inside selectedValue watch")
            this.triggerAction(newValue);
        }
    },
    computed: {
        ...mapGetters('forecastsStore', ['getRegionalForecastTimes', 'isLoading']),
    },
    methods: {
        ...mapActions('forecastsStore', ['changeRegionalForecastTime']),
        onSliderInput() {
            this.selectedValue = parseInt(this.selectedValue); // Ensure it's an integer
            // if user changes value while
            // console.log("SLIDE INPUT");
            this.pause();
        },
        triggerAction(value) {
            // console.log('Selected value:', value);
            const selectedTime = this.getRegionalForecastTimes[value - 1];
            this.changeRegionalForecastTime(selectedTime);
        },
        togglePlayStop() {
            // console.log("toggling ...")
            this.playing = !this.playing;
            if (this.playing) {
                this.play();
            } else {
                this.pause();
            }
        },
        play() {
            if (this.selectedValue > this.minValue) {
                this.selectedValue = this.minValue;
            }
            this.intervalId = setInterval(() => {
                // console.log("tick ... selectedValue = " + this.selectedValue + "; this.maxValue = " + this.maxValue)
                if (this.selectedValue < this.maxValue) {
                    this.selectedValue++;
                } else {
                    this.selectedValue = this.minValue;
                    this.togglePlayStop();
                }
            }, 900);
        },
        pause() {
            if (this.playing) {
                console.log("Stopping forecast replay ...")
                this.playing = false;
            }
            clearInterval(this.intervalId);
        },
        resetPlay() {
            this.pause();
            if (this.getRegionalForecastTimes && this.getRegionalForecastTimes.length > 0) {
                this.selectedValue = this.minValue;
            }
        },
    },
    components: {BButton}
};
</script>

<style scoped>
.custom-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
}

input[type="range"] {
    width: 100%;
}

.slider-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    font-size: x-large;
}

.play-button {
    margin-top: 10px;
    width: 70px;
}

</style>
