<template>

    <div style="margin-top: 40px">
        <h6 class="centered" style="font-size: 22px; font-weight: 600;">Přesnost v posledních hodinách</h6>

        <div class="row">
            <div class="col-12">
                <p style="margin-top: 25px">
                    Vyhodnocení přesnosti jednotlivých modelů v posledních hodinách pro <span style="font-weight: 600">širší region vyhledané lokality</span>.
                </p>

                <div class="accordion" style="margin-top: 25px">
                    <b-card v-if="this.pastHoursVerificationExistFor(1)" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" style="height: 35px">
                            <b-button block v-b-toggle.accordion-1 variant="light">Poslední hodina</b-button>
                        </b-card-header>
                        <b-collapse visible id="accordion-1" class="mt-0">
                            <b-card-body style="padding-bottom: 0">
                                <ForecastPastHourVerificationTable v-bind:hours=1></ForecastPastHourVerificationTable>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card v-if="this.pastHoursVerificationExistFor(3)" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" style="height: 35px">
                            <b-button block v-b-toggle.accordion-3 variant="light">3 poslední hodiny</b-button>
                        </b-card-header>
                        <b-collapse :visible="!this.pastHoursVerificationExistFor(1)" id="accordion-3" class="mt-0">
                            <b-card-body style="padding-bottom: 0">
                                <ForecastPastHourVerificationTable v-bind:hours=3></ForecastPastHourVerificationTable>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card v-if="this.pastHoursVerificationExistFor(6)" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" style="height: 35px">
                            <b-button block v-b-toggle.accordion-6 variant="light">6 posledních hodin</b-button>
                        </b-card-header>
                        <b-collapse :visible="!this.pastHoursVerificationExistFor(3)" id="accordion-6" class="mt-0">
                            <b-card-body style="padding-bottom: 0">
                                <ForecastPastHourVerificationTable v-bind:hours=6></ForecastPastHourVerificationTable>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card v-if="this.pastHoursVerificationExistFor(12)" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" style="height: 35px">
                            <b-button block v-b-toggle.accordion-12 variant="light">12 posledních hodin</b-button>
                        </b-card-header>
                        <b-collapse :visible="!this.pastHoursVerificationExistFor(6)" id="accordion-12" class="mt-0">
                            <b-card-body style="padding-bottom: 0">
                                <ForecastPastHourVerificationTable v-bind:hours=12></ForecastPastHourVerificationTable>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card v-if="this.pastHoursVerificationExistFor(24)" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" style="height: 35px">
                            <b-button block v-b-toggle.accordion-24 variant="light">24 posledních hodin</b-button>
                        </b-card-header>
                        <b-collapse :visible="!this.pastHoursVerificationExistFor(12)" id="accordion-24" class="mt-0">
                            <b-card-body style="padding-bottom: 0">
                                <ForecastPastHourVerificationTable v-bind:hours=24></ForecastPastHourVerificationTable>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {utilsMixin} from "@/components/reusables/utils-mixin";
import {BCard} from 'bootstrap-vue';
import {BCardHeader} from 'bootstrap-vue';
import {BCollapse} from 'bootstrap-vue';
import {BCardBody} from 'bootstrap-vue';
import {BButton} from 'bootstrap-vue';
import {VBToggle} from 'bootstrap-vue';
import Vue from "vue";
import ForecastPastHourVerificationTable from "@/components/forecast/ForecastPastHourVerificationTable.vue";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)


export default {
    name: 'ForecastPastHourVerifications',
    mixins: [utilsMixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters('forecastsStore', ['pastHoursVerificationExistFor'])
    },
    methods: {},
    components: {
        ForecastPastHourVerificationTable,
        BCollapse,
        BCardBody,
        BCardHeader,
        BCard,
        BButton,
    }
}
</script>
